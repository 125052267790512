import React from 'react';
import PropTypes from 'prop-types';
import setComponentsId from 'helpers/setComponentsId';
import classNames from 'classnames';
import { translate } from 'react-translate';

import { Tabs, Tab } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import DiiaSignForm from './DiiaSignForm';
import FileKeySignForm from './FileKeySignForm';
import HardwareKeySignForm from './HardwareKeySignForm';

const styles = theme => ({
    tab: {
        fontSize: 16,
        textTransform: 'inherit',
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            margin: 0,
            padding: 0
        }
    },
    tabsRoot: {
        [theme.breakpoints.down('sm')]: {
            margin: '0!important'
        }
    },
    containerXs: {
        [theme.breakpoints.down('sm')]: {
            padding: '0!important',
            justifyContent: 'space-between'
        }
    }
});

const EDSForm = ({ t, classes, setId, diiaSign, ...rest }) => {
    const [tab, setTab] = React.useState(0);
    const [busy, setBusy] = React.useState(false);

    return (
        <>
            <Tabs
                value={tab}
                disabled={busy}
                onChange={(event, value) => setTab(value)}
                indicatorColor="primary"
                textColor="primary"
                id={setId('tabs')}
                className={classes.tabsWrapper}
                classes={
                    {
                        flexContainer: classNames(classes.tabsContainer, classes.containerXs),
                        root: classes.tabsRoot
                    }
                }
            >
                <Tab
                    disabled={busy}
                    label={t('FileKeySignMethod')}
                    id={setId('tab-file-key')}
                    className={classNames(classes.tab, classes.tabButton)}
                />
                <Tab
                    disabled={busy}
                    label={t('HardwareKeySignMethod')}
                    id={setId('tab-hardware-key')}
                    className={classNames(classes.tab, classes.tabButton)}
                />
                {diiaSign ? (
                    <Tab
                        disabled={busy}
                        label={t('DiiaSignMethod')}
                        id={setId('tab-diia-sign')}
                        className={classNames(classes.tab, classes.tabButton)}
                    />
                ) : null}
            </Tabs>
            {tab === 0 ? (
                <FileKeySignForm
                    {...rest}
                    busy={busy}
                    setBusy={setBusy}
                    setId={elementName => setId(`file-key-${elementName}`)}
                />
            ) : null}
            {tab === 1 ? (
                <HardwareKeySignForm
                    {...rest}
                    busy={busy}
                    setBusy={setBusy}
                    setId={elementName => setId(`hardware-key-${elementName}`)}
                />
            ) : null}
            {tab === 2 ? (
                <DiiaSignForm
                    {...rest}
                    setBusy={setBusy}
                />
            ) : null}
        </>
    );
};

EDSForm.propTypes = {
    setId: PropTypes.func,
    t: PropTypes.func.isRequired,
    onSelectKey: PropTypes.func.isRequired
};

EDSForm.defaultProps = {
    setId: setComponentsId('sign-form')
};

const styled = withStyles(styles)(EDSForm);
export default translate('SignForm')(styled);
