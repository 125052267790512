import React from 'react';
import { translate } from 'react-translate';
import { Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import generatePassword from 'password-generator';
import BlockScreen from 'components/BlockScreen';
import store from 'store';
import config from 'config.json';
import diiaGovId from 'assets/img/id-gov-ua-diia.svg';
import * as api from './../../../services/api';
import logo from './../../../assets/img/gov-id-logo.svg';

const { APP_NAME } = config;

const st = localStorage.getItem('state');
class GovIdButton extends React.Component {
    state = {
        codeError: null,
        preloader: false
    };

    componentDidMount = () => {
        if (!st) {
            const signa = generatePassword(20, false);
            localStorage.setItem('state', signa);
        }

        const params = window.location.search;

        if (!params.length) return;

        const param = params.replace('?', '').split('&');

        const newState = {};

        param.forEach(item => {
            const paramName = item.split(/=(.+)/)[0];
            const paramValue = item.split(/=(.+)/)[1];

            newState[paramName] = paramValue;
        });

        const { code, state } = newState;

        const data = {
            code,
            state
        };

        const sign = localStorage.getItem('state');

        if (sign === state) {
            this.setState({ preloader: true });

            api.post('authorise/govid', data, 'SET_GOV_ID', store.dispatch).then(result => {
                console.log('RESULT', result);
                if (result instanceof Error) {
                    this.setState({
                        codeError: result,
                        preloader: false
                    });
                    return null;
                } else {
                    window.location.reload();
                    localStorage.removeItem('state');
                }
            });
        } else {
            this.setState({
                codeError: 'Error'
            });
            return null;
        }
    };

    handleClick = () => {
        this.setState({ preloader: true });

        const signature = localStorage.getItem('state');

        api.get(`authorise/govid/info?state=${signature}`, 'GET_GOV_ID', store.dispatch)
            .then(result => window.location.replace(result.totalAuthUrl))
            .catch(() => this.setState({ preloader: false }));
    };

    render() {
        const { t, classes } = this.props;
        const { codeError, preloader } = this.state;

        return (
            <>
                {preloader ? <BlockScreen open={true} /> : null}
                {
                    codeError ? (
                        <>
                            {
                                ['diia', 'grants', 'eco'].includes((APP_NAME || '').toLowerCase()) ? (
                                    <div className={classes.diiaDisclaimer}>
                                        <div className={classes.errorTextWrapper}>
                                            <span role="img" aria-label="emoji" className={classes.icon}>🤷‍♂</span>
                                            <Typography variant="headline" className={classes.discText}>{t('NOIPN')}</Typography>
                                        </div>
                                        <Typography variant="subheading" className={classes.subheading}>{t('NOIPN_DESCRIPTION')}</Typography>
                                    </div>
                                ) : (
                                    <div className={classes.disclaimer}>
                                        <Typography variant="headline">{t('NOIPN')}</Typography>
                                        {' '}
                                        <Typography variant="subheading">{t('NOIPN_DESCRIPTION')}</Typography>
                                    </div>
                                )
                            }
                        </>
                    ) : null
                }
                <div className={classes.wrap}>
                    {
                        ['diia', 'grants', 'eco'].includes((APP_NAME || '').toLowerCase()) ? (
                            <Button
                                fullWidth={true}
                                className={classes.diiaAuthBtn}
                                onClick={this.handleClick}
                            >
                                <span className={classes.diiaText}>{t('AuthWith') + ' '}</span>
                                <img className={classes.diiaLogo} src={diiaGovId} alt="Logo" />
                            </Button>
                        ) : (
                            <Button
                                fullWidth={true}
                                className={classes.authBtn}
                                onClick={this.handleClick}
                            >
                                <span className={classes.text}>{t('AuthWith')}{' '}</span>
                                <img className={classes.govIdLogo} src={logo} alt="Logo" />
                            </Button>
                        )
                    }
                    <Typography
                        className={classes.description}
                        variant="caption"
                        display="block"
                        gutterBottom
                    >
                        {t('AuthWithText')}
                    </Typography>
                </div>
            </>
        );
    }
}

const styles = theme => ({
    errorTextWrapper: {
        display: 'flex',
        backgroundColor: '#fff7e3'
    },
    wrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
        ['@media (max-width:610px)']: { // eslint-disable-line no-useless-computed-key
            display: 'block'
        }
    },
    description: {
        margin: 0,
        lineHeight: '18px',
        paddingLeft: 20,
        ['@media (max-width:610px)']: { // eslint-disable-line no-useless-computed-key
            paddingLeft: '0',
            lineHeight: 'normal',
            marginTop: 10
        }
    },
    authBtn: {
        minWidth: 240,
        height: 50,
        display: 'flex',
        color: '#fff',
        padding: '10px 15px',
        border: '2px solid transparent',
        background: "linear-gradient(270deg, #314260, #365a96)",
        boxShadow: '0 0 10px #fff',
        borderRadius: 4,
        '&:hover': {
            color: '#fff',
            textDecoration: 'unset',
            background: "linear-gradient(270deg, #204c92, #3a6cbe)",
            boxShadow: '0 0 10px #3a6cbe',
            transition: '0.2s ease-in-out'
        },
        '&:active': {
            border: '2px solid transparent',
            background: "linear-gradient(270deg, #314260, #365a96)"
        },
        '&:focus': {
            color: '#fff',
            textDecoration: 'unset',
            border: '2px solid #498af2',
        },
        '&:visited': {
            color: '#fff',
            textDecoration: 'unset',
        }
    },
    diiaAuthBtn: {
        minWidth: 240,
        height: 50,
        display: 'flex',
        color: '#000',
        border: '2px solid #000',
        boxShadow: '0 0 10px #fff',
        borderRadius: 4,
        '&:hover': {
            transition: '0.2s ease-in-out'
        }
    },
    text: {
        fontSize: '14px',
        paddingRight: '4px',
        lineHeight: '20px',
        fontWeight: '600',
        textTransform: 'initial'
    },
    diiaText: {
        fontSize: '14px',
        paddingRight: '4px',
        lineHeight: '20px',
        fontWeight: '600',
        textTransform: 'initial',
        whiteSpace: 'nowrap',
        letterSpacing: '-0.02em',
        ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '13px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        }
    },
    logo: {
        height: 35
    },
    diiaLogo: {
        height: 33,
        paddingLeft: 5,
        ['@media (max-width:650px)']: { // eslint-disable-line no-useless-computed-key
            height: 30
        },
        [theme.breakpoints.down('sm')]: {
            height: 25
        }
    },
    emptyWrap: {
        marginTop: -50
    },
    diiaDisclaimer: {
        backgroundColor: '#fff7e3',
        padding: '25px 18px',
        marginBottom: 20,
        maxWidth: 1120,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 15
        }
    },
    icon: {
        fontSize: 38,
        marginRight: 10
    },
    discText: {
        fontWeight: 300,
        fontSize: 20
    },
    subheading: {
        display: 'block',
        padding: '15px 0 0 50px'
    },
    disclaimer: {
        backgroundColor: '#fec5c5',
        padding: '25px 18px',
        marginBottom: 20,
        maxWidth: 1120,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 15
        }
    },
    govIdLogo: {
        maxWidth: 100,
        marginLeft: 5
    }
});

GovIdButton.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

const translated = translate('LoginPage')(GovIdButton);

export default withStyles(styles)(translated);
