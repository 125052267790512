import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import getCookie from 'helpers/getCookie';
import { AppBar, Toolbar, Divider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import diiaLogo from 'assets/img/diia-logo.svg';
import uaSign from 'assets/img/uasign.svg';
import grantsLogo from 'assets/img/logo/logo-header.svg';
import eveteranLogo from 'assets/img/logo/eveteranLogo.png';

import config from 'config.json';

const { APP_NAME } = config;

const styles = theme => ({
    header: {
        padding: 20,
        background: '#fff',
        color: '#000',
        [theme.breakpoints.down('sm')]: {
            padding: 8
        }
    },
    button: {
        lineHeight: '3em',
        color: '#000',
        cursor: 'pointer'
    },
    link: {
        color: '#000000',
        textDecoration: 'none',
        textDecorationColor: '#000'
    },
    linkText: {
        verticalAlign: 'super',
        marginLeft: 8,
        transition: '.2s ease-in-out',
        textDecorationColor: '#000',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    imageSrc: {
        width: 48,
        height: 48,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: 32,
            height: 32,
            backgroundSize: 'cover'
        }
    },
    toolbar: {
        padding: '0 4px',
        minHeight: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
        '& > *': {
            marginRight: 8
        },
        '& > *:last-child': {
            marginRight: 0
        }
    },
    whiteSm: {
        flexGrow: 1
    },
    divider: {
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: 7
        }
    },
    iconContainer: {
        width: 48,
        height: 48,
        borderBottom: '1px solid transparent!important',
        [theme.breakpoints.down('sm')]: {
            width: 32,
            height: 32,
            alignItems: 'center',
            display: 'flex'
        }
    },
    grants: {
        width: 284,
        height: 48,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: 90,
            backgroundSize: 'cover'
        }
    },
    logo: {
        maxWidth: 160
    }
});

const BACK_LINKS = {
    'ecozagroza-prod-app': 'https://ecozagroza.gov.ua/',
    'grants': 'https://grants.vzaemo.diia.gov.ua/',
    'diia': 'https://diia.gov.ua/',
    'eco': 'https://eco.gov.ua/'
};

const Header = ({ classes, t }) => {
    const appName = APP_NAME?.toLowerCase();

    const getBackLink = (() => {
        const source = [appName, getCookie('client_id')];
        const current = Object.keys(BACK_LINKS).find((name) => source.includes(name));
        return BACK_LINKS?.[current] || '/';
    })();

    return (
        <AppBar className={classes.header} position="relative" elevation={0}>
            <Toolbar className={classes.toolbar}>
                <a href={getBackLink} className={classes.link}>
                    <Typography className={classes.button}>
                        <span className={classes.iconContainer}>
                            <ArrowBackIcon />
                        </span>
                        <span className={classes.linkText}>{t('BackToMain')}</span>
                    </Typography>
                </a>
    
                <span className={classes.whiteSm} />
    
                {
                    appName === 'diia' ? (
                        <>
                            <a href="https://diia.gov.ua/" className={classes.link}>
                                <span
                                    className={classNames(classes.button, classes.imageSrc)}
                                    style={{
                                        backgroundImage: `url(${uaSign})`
                                    }}
                                />
                            </a>
                            <a href="https://diia.gov.ua/">
                                <span
                                    className={classNames(classes.button, classes.imageSrc)}
                                    style={{
                                        backgroundImage: `url(${diiaLogo})`
                                    }}
                                />
                            </a>
                        </>
                    ) : null
                }

                {
                    appName === 'grants' ? (
                        <a href="https://grants.vzaemo.diia.gov.ua/" className={classes.link}>
                            <span
                                className={classNames(classes.button, classes.grants)}
                                style={{
                                    backgroundImage: `url(${grantsLogo})`
                                }}
                            />
                        </a>
                    ) : null
                }

                {
                    appName === 'eveteran_public' ? (
                        <img
                            src={eveteranLogo}
                            alt="logo"
                            className={classes.logo}
                        />
                    ) : null
                }
            </Toolbar>
            <Divider className={classes.divider} />
        </AppBar>
    );
};

Header.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth: { userUnits, info } }) => ({ userUnits, userInfo: info });

const translated = translate('Navigator')(Header);
const connected = connect(mapStateToProps)(translated);
export default withStyles(styles)(connected);
