import React from 'react';
import { translate } from 'react-translate';

import makeStyles from '@mui/styles/makeStyles';

import { Card, CardContent, Button, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';

import appstore from 'assets/img/appstore.svg';
import googleplay from 'assets/img/googleplay.svg';
import appgallery from 'assets/img/appgallery.svg';

import useDiiaSign from 'services/diiaSign/useDiiaSign';

import isCyrillic from 'helpers/isCyrillic';

import config from 'config';

import SigningResult from 'components/EDSForm/DiiaSignForm/components/SigningResult';

const { diiaSignUrl } = config;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        paddingTop: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            paddingTop: 0
        }
    },
    retry: {
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
            marginTop: 20
        }
    },
    processing: {
        marginRight: 4
    },
    processingText: {
        marginTop: 10
    },
    button: {
        marginTop: 20,
        maxWidth: 200,
        [theme.breakpoints.down('md')]: {
            marginBottom: 10
        }
    },
    mainTitle: {
        display: 'block',
        fontSize: '56px',
        lineHeight: '64px',
        marginBottom: 32,
        [theme.breakpoints.down('md')]: {
            fontSize: '36px',
            lineHeight: '40px',
        }
    },
    subTitle: {
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: 44,
        maxWidth: 640,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    },
    title: {
        fontSize: '18px',
        lineHeight: '28px',
        marginTop: '40px',
        marginBottom: '20px',
        [theme.breakpoints.down('md')]: {
            marginTop: '20px',
            marginBottom: '16px',
            fontSize: '18px',
            lineHeight: '28px',
        }
    },
    container: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    item: {
        cursor: 'pointer',
        marginRight: 20,
        [theme.breakpoints.down('lg')]: {
            marginBottom: 10
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10
        }
    },
    cardRoot: {
        paddingTop: 0,
        paddingLeft: 32,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            textAlign: 'center'
        }
    }
}));

const EXPIRE_TIME = 180;

const DiiaSignForm = ({ t, onSignHash, getDataToSign, auth = false, diia, removeActiveItem }) => {
    const classes = useStyles();
    const [signingError, setSigningError] = React.useState();
    const [showErrorDialog, setShowErroDialog] = React.useState(false);
    const [preparing, setPreparing] = React.useState(true);
    const [processing, setProcessing] = React.useState(false);
    const [timer, setTimer] = React.useState(0);

    const {
        error,
        loading,
        finished,
        deepLink,
        setDataToSign
    } = useDiiaSign({
        auth,
        url: diiaSignUrl,
        handleSign: async signs => {
            try {
                setProcessing(true);
                await onSignHash(signs);
                setProcessing(false);
            } catch (error) {
                setSigningError(isCyrillic(error.message) ? error.message : t(error.message));
                setShowErroDialog(true);
            }
        }
    });

    const updateData = React.useCallback(async () => {
        try {
            setPreparing(true);
            const { data } = await getDataToSign();
            setDataToSign(data);
            setPreparing(false);
        } catch (error) {
            setSigningError(isCyrillic(error.message) ? error.message : t(error.message));
            setShowErroDialog(true);
        }
    }, [t, setDataToSign, getDataToSign]);

    React.useEffect(() => {
        if (!getDataToSign) {
            return;
        }
        updateData();
    }, []);

    React.useEffect(() => {
        const timerListener = setTimeout(() => setTimer(timer + 1), 1000);

        if (timer <= EXPIRE_TIME) return;

        if (!(deepLink && !processing && !loading && !error && !preparing)) return;

        setTimer(0);
        updateData();
        return () => clearTimeout(timerListener);
    }, [timer, deepLink, processing, loading, error, preparing, updateData]);

    const changingStateText =
        preparing ? t('DiiaSignFormLoading') :
            finished ? t('DiiaSignFormSuccess') :
                error ? t('DiiaSignFormError') :
                    loading ? t('PreparingDeeplink') : null;

    return (
        <>
            {
                diia ?
                    <>
                        <Typography
                            variant="headline"
                            gutterBottom={true}
                            className={classes.mainTitle}
                        >
                            {t('DiiaSignTitle')}
                        </Typography>
                        <Typography
                            variant="headline"
                            gutterBottom={true}
                            className={classes.subTitle}
                        >
                            {t('DiiaSignFormDescription')}
                        </Typography>
                    </>
                : null
            }
            
            <Card className={classes.root} elevation={0}>
                <SigningResult
                    error={error}
                    loading={loading}
                    deepLink={deepLink}
                    finished={finished}
                />
                <CardContent
                    classes={
                        {
                            root: classes.cardRoot
                        }
                    }
                >
                    <Typography gutterBottom variant="h6" component="h6">
                        {t('DiiaSignFormTitle')}
                    </Typography>
                    {
                        changingStateText ? (
                            <Typography variant="body2" color="textSecondary" component="p">
                                {changingStateText}
                            </Typography>
                        ) : null
                    }
                    {
                        error ? (
                            <Button
                                onClick={updateData}
                                autoFocus={true}
                                variant="outlined"
                                className={classes.retry}
                            >
                                {t('Retry')}
                            </Button>
                        ) : null
                    }
                    {
                        processing ? (
                            <Typography className={classes.processingText}>
                                <CircularProgress size={12} className={classes.processing} />
                                {t('Processing')}
                            </Typography>
                        ) : null
                    }
                </CardContent>
            </Card>

            {
                diia ? (
                    <>
                        <Typography
                            variant="headline"
                            gutterBottom={true}
                            className={classes.title}
                        >
                            {t('DownloadApp')}
                        </Typography>

                        <div className={classes.container}>
                            <a href="https://apps.apple.com/us/app/%D0%B4%D1%96%D1%8F/id1489717872" className={classes.item} target="_blank" rel="noopener noreferrer">
                                <img src={appstore} alt="AppStore" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=ua.gov.diia.app" className={classes.item} target="_blank" rel="noopener noreferrer">
                                <img src={googleplay} alt="GooglePlay" />
                            </a>
                            <a href="https://appgallery.cloud.huawei.com/marketshare/app/C102057503?locale=en_US&source=appshare&subsource=C102057503" className={classes.item} target="_blank" rel="noopener noreferrer">
                                <img src={appgallery} alt="AppGallery" />
                            </a>
                        </div>

                        <Button
                            onClick={removeActiveItem}
                            variant="outlined"
                            className={classes.button}
                        >
                            {t('Back')}
                        </Button>
                    </>
                ) : null
            }

            <Dialog
                open={showErrorDialog}
                onClose={() => setShowErroDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialog}
            >
                <DialogTitle
                    className={classes.dialogContentWrappers}
                >
                    {t('SigningDataError')}
                </DialogTitle>
                <DialogContent className={classes.dialogContentWrappers}>
                    <DialogContentText>
                        {signingError}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogContentWrappers}>
                    <Button
                        color="primary"
                        onClick={() => setShowErroDialog(false)}
                        autoFocus={true}
                    >
                        {t('CloseDialog')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default translate('DiiaSignForm')(DiiaSignForm);
