import config from 'config.json';
import court from './court';
import bpmn from './bpmn.json';
import dfrr from './dfrr';
import dpss from './dpss.json';
import morrich from './morrich.json';
import mvs from './mvs.json';
import diia from './diia.json';
import intellect from './intellect.json';
import nazk from './nazk.json';
import grants from './grants.json';
import vru from './vru.json';
import eveteran from './eveteran.json';
import vykryvachi from './vykryvachi.json';
import sport from './sport.json';
import bpmn_front_kyiv from './kyiv.json';
import eco from './eco.json';
import eveteran_public from './eveteran_public.json';

const themes = {
    court,
    bpmn,
    dfrr,
    dpss,
    morrich,
    mvs,
    diia,
    intellect,
    nazk,
    grants,
    vru,
    eveteran,
    vykryvachi,
    sport,
    bpmn_front_kyiv,
    eco,
    eveteran_public
};

export default themes[(config.APP_NAME || 'court').toLowerCase()];
