import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Layout from 'layouts/topHeader';
import EDSForm from 'components/EDSForm';

import config from 'config';

import GovIdButton from './GovIdButton';

const { GOV_ID, diiaSign } = config;

const styles = {
    title: {
        fontSize: '1.4rem',
        display: 'inline-block',
        marginTop: 20,
        marginBottom: 20
    }
};

const DefaultLoginLayout = ({ t, classes, setId, onSelectKey, getDataToSign, onSignHash, auth }) => (
    <Layout
        setId={setId}
    >
        {GOV_ID && <GovIdButton />}
        <Typography
            variant="headline"
            gutterBottom={true}
            id={setId('title')}
            className={classes.title}
        >
            {t(GOV_ID ? 'GOV_ID_TITLE' : 'TITLE')}
        </Typography>
        <EDSForm
            auth={auth}
            diiaSign={diiaSign}
            onSelectKey={onSelectKey}
            onSignHash={onSignHash}
            getDataToSign={getDataToSign}
            setId={elementName => setId(`sign-form-${elementName}`)}
        />
    </Layout>
);

DefaultLoginLayout.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.func.isRequired,
    setId: PropTypes.func,
    onSelectKey: PropTypes.func,
    diiaSign: PropTypes.bool
};

DefaultLoginLayout.defaultProps = {
    setId: () => null,
    onSelectKey: () => null,
    diiaSign: false
};

const styled = withStyles(styles)(DefaultLoginLayout);
export default translate('LoginPage')(styled);
