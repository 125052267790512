import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import { Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Layout from 'layouts/RightSidebarLayout';
import EDSForm from 'components/EDSForm';

import config from 'config';
import GovIdButton from './GovIdButton';
const { GOV_ID, diiaSign } = config;

const styles = theme => ({
    mainTitle: {
        display: 'block',
        fontSize: '48px',
        lineHeight: '50px',
        maxWidth: '500px',
        marginBottom: '0.5em',
        [theme.breakpoints.down('md')]: {
            fontSize: '36px',
            lineHeight: '40px',
        }
    },
    title: {
        fontSize: '28px',
        lineHeight: '32px',
        marginTop: '30px',
        marginBottom: '30px',
        [theme.breakpoints.down('md')]: {
            marginTop: '20px',
            marginBottom: '20px',
            fontSize: '21px',
            lineHeight: '26px',
        }
    },
    subtitle: {
        marginTop: 32,
        marginBottom: 32,
        fontSize: '36px',
        lineHeight: '40px',
        [theme.breakpoints.down('md')]: {
            fontSize: '28px',
            lineHeight: '30px',
        }
    },
    headline: {
        marginBottom: 32
    },
    subheading: {
        marginBottom: 18
    }
});

const DiiaLoginLayout = ({ t, classes, setId, onSelectKey, getDataToSign, onSignHash, auth }) => (
    <Layout
        setId={setId}
        details={(
            <React.Fragment>
                <Typography variant="headline" className={classes.headline}>{t('HELPER_TITLE')}</Typography>
                <Typography variant="subheading" className={classes.subheading}>{t('HELPER_SUBTITLE1')}</Typography>
                <Typography variant="subheading" className={classes.subheading}>{t('HELPER_SUBTITLE2')}</Typography>
                <Typography variant="subheading" className={classes.subheading}>{t('HELPER_SUBTITLE3')}</Typography>
            </React.Fragment>
        )}
    >
        <Typography
            variant="headline"
            gutterBottom={true}
            id={setId('mainTitle')}
            className={classes.mainTitle}
        >
            {t('MAIN_TITLE')}
        </Typography>

        {GOV_ID && <GovIdButton />}

        <Typography
            variant="headline"
            gutterBottom={true}
            id={setId('title')}
            className={classes.title}
        >
            {t(GOV_ID ? 'GOV_ID_TITLE' : 'TITLE')}
        </Typography>
        {/* <Typography
            variant="headline"
            gutterBottom={true}
            id={setId('title')}
            className={classes.subtitle}
        >
            {t('SUBTITLE')}
        </Typography> */}
        <EDSForm
            auth={auth}
            diiaSign={diiaSign}
            centered={false}
            onSelectKey={onSelectKey}
            onSignHash={onSignHash}
            getDataToSign={getDataToSign}
            setId={elementName => setId(`sign-form-${elementName}`)}
        />
    </Layout>
);

DiiaLoginLayout.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.func.isRequired,
    setId: PropTypes.func,
    onSelectKey: PropTypes.func,
    diiaSign: PropTypes.bool
};

DiiaLoginLayout.defaultProps = {
    setId: () => null,
    onSelectKey: () => null,
    diiaSign: true
};

const styled = withStyles(styles)(DiiaLoginLayout);
export default translate('LoginPage')(styled);
