import App from 'containers/App';
import PageNotFoundScreen from 'components/PageNotFoundScreen';
// import Faq from 'containers/Faq';
import Terms from 'containers/Terms';
// import About from 'containers/About';
// import Policy from 'containers/Policy';
import setId from 'helpers/setComponentsId';

// import TwoFactorAuth from 'pages/TwoFactorAuth';

import theme from 'themes';

const indexRoutes = [
    // {
    //     path:      '/test',
    //     component: TwoFactorAuth,
    //     setId:     setId('twoFactorAuth')
    // },
    // {
    //     path:      '/faq',
    //     component: Faq,
    //     setId:     setId('faq')
    // },
    // {
    //     path:      '/about',
    //     component: About,
    //     setId:     setId('about')
    // },
    // {
    //     path:      '/policy',
    //     component: Policy,
    //     setId:     setId('policy')
    // },
    {
        path: '/',
        component: App,
        setId: setId('app')
    },
    {
        path: '*',
        component: PageNotFoundScreen
    }
];

if (!theme.hideTermsLink) {
    indexRoutes.unshift({
        path: '/terms',
        component: Terms,
        setId: setId('terms')
    });
}

export default indexRoutes;
