import React, { Component } from 'react';
import PropTypes from 'prop-types';
import setComponentsId from 'helpers/setComponentsId';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { Snackbar, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import style from 'assets/jss';

import { signUpConfirmation, getAuth } from 'actions/auth';

import config from 'config.json';

import Layout from 'layouts/leftSidebar';
import BlockScreen from 'components/BlockScreen';
import RegisterForm from './components/RegisterForm';

class RegisterPage extends Component {
    state = {
        values: this.props.values,
        error: '',
        blockScreen: true
    };

    componentDidMount = async () => {
        const { FORCE_REGISTER } = config || {};
        const { values } = this.state;

        if (!FORCE_REGISTER) {
            this.setState({ blockScreen: false });
            return;
        }

        this.handleSubmit(values);
    };

    handleSubmit = async (values) => {
        delete values.agreement;
        this.setState({ blockScreen: true });
        const { FORCE_REGISTER } = config || {};
        const { success, redirect, err, message } = await signUpConfirmation(values);
        if (success) {
            // this.setState({ blockScreen: false });
            return window.location.replace(redirect);
        }
        const error = message || err;

        if (error && FORCE_REGISTER) {
            window.location.href = '/logout';
        }

        if (!error) {
            await getAuth();
            const { success, redirect } = await signUpConfirmation(values);
            if (success) {
                return window.location.replace(redirect);
            }
        }

        return this.setState({ error, blockScreen: false }, () => console.log(error));
    };

    closeError = () => this.setState({ error: '', blockScreen: false });

    render() {
        const { setId, t, classes } = this.props;
        const { values, error, blockScreen } = this.state;

        // if (blockScreen) {
        //     return <BlockScreen open={true} />;
        // }

        return (
            <Layout setId={elementName => setId(`left-side-bar-${elementName}`)}>
                <BlockScreen open={blockScreen} />
                {error &&
                    <Snackbar
                        id={setId('error')}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        message={
                            <span id="message-id" name={error}>
                                {/* {t('ERROR')} */}
                                {t(error)}
                            </span>
                        }
                        action={[
                            <Button
                                key="close-error"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={this.closeError}
                                className={classes.smallButton}
                            >
                                OK
                            </Button>
                        ]}
                    />
                }
                <RegisterForm
                    values={values}
                    onSubmit={this.handleSubmit}
                    setId={elementName => setId(`register-form-${elementName}`)}
                />
            </Layout>
        );
    }
}

RegisterPage.propTypes = {
    setId: PropTypes.func,
    values: PropTypes.object
};

RegisterPage.defaultProps = {
    setId: setComponentsId('app'),
    values: {}
};

const styled = withStyles(style)(RegisterPage);
const translated = translate('RegisterForm')(styled);

function mapStateToProps(state) {
    return { auth: state.authorization };
}

export default connect(mapStateToProps)(translated);
