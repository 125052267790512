import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import EmptyPage from 'components/EmptyPage';
import Error503 from 'components/503';
import config from 'config.json';

const styles = {
    wrap: {
        paddingTop: 64,
        paddingLeft: 260,
        '@media (max-width: 959px)': {
            paddingLeft: 0
        }
    }
};

class Auth extends React.Component {
    state = { ready: false };

    render() {
        const { classes, children, t, DBError, ERROR_503 } = this.props;
        if (DBError) {
            return (
                <div className={classes.wrap}>
                    <EmptyPage
                        title={t('ERROR')}
                        description={t('ERROR_DESCRIPTION')}
                    />
                </div>
            );
        }
        if (ERROR_503 && config && (config.APP_NAME.toLowerCase() === 'court')) {
            return <Error503 />;
        }
        return children;
    }
}

Auth.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    DBError: PropTypes.bool.isRequired,
    ERROR_503: PropTypes.bool.isRequired
};

Auth.defaultProps = {
    children: <div />
};

const translated = translate('Auth')(Auth);
export default connect(({ auth: { DBError, ERROR_503 } }) =>
    ({ DBError, ERROR_503 }))(withStyles(styles)(translated));
