import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import jwt_decode from "jwt-decode";
import moment from 'moment';

import setComponentsId from 'helpers/setComponentsId';
import { requestSignData, checkSignData } from 'actions/eds';
import { getAuth/*, logout*/ } from 'actions/auth';
// import hashToInternalSign from 'services/eds/helpers/hashToInternalSign';

import config from 'config';
import DefaultLoginLayout from './components/DefaultLoginLayout';
import DiiaLoginLayout from './components/DiiaLoginLayout';
import NewDiiaLoginLayout from './components/NewDiiaLoginLayout';
import translate from 'react-translate/lib/translate';

const { APP_NAME, eds } = config;

const { useEncodeCert } = eds || {};

class LoginPage extends Component {
    state = { error: null };

    getEncodeCert = async (signer, index) => {
        const certif = await signer.execute('EnumOwnCertificates', index);

        if (certif === null) {
            throw new Error('Сертифікат шифрування відсутній. Зверніться до вашого АЦСК');
        }

        if (certif.keyUsage === 'Протоколи розподілу ключів') {
            return certif;
        }

        return this.getEncodeCert(signer, index + 1);
    };

    getDataToSign = async () => {
        const { t } = this.props;
        const { token } = await requestSignData();

        //  var { exp } = jwt_decode(token);

        this.setState({ token });
        return { data: [{ name: t('Authorization'), data: token }], expireTime: /*exp * 1000*/ Date.parse(moment().add(3, 'minutes').toDate()) };
    };

    onSignHash = async ([{ signature }] = [{}]) => {
        const { t } = this.props;
        const { token } = this.state;
        // let signature = hashSignature;
        // try {
        //     const internal = await hashToInternalSign({ internal: true, signature, data: token });
        //     signature = internal.signature;
        // } catch (e) {
        //     // Nothing to do.
        // }

        const signData = { signature, token };
        const result = await checkSignData(signData);

        if (result instanceof Error) {
            await getAuth();
            throw new Error(t(result.message, { details: result.details }));
        }

        await getAuth();
    };

    signDataAndLogin = async (cert, signer, resetPrivateKey) => {
        const { t } = this.props;
        // await getAuth();
        // await logout();

        const { token } = await requestSignData();
        const signature = await signer.execute('SignData', token, true);

        const signData = { signature, token };

        if (useEncodeCert) {
            const { issuer, serial } = await this.getEncodeCert(signer, 0);

            const decodedCert = await signer.execute('GetCertificate', issuer, serial);
            const encodeCert = await signer.execute('Base64Encode', decodedCert);

            signData.encodeCert = encodeCert;
            signData.encodeCertSerial = serial;
        }

        // debugger;
        const result = await checkSignData(signData);

        if (result instanceof Error) {
            await getAuth();
            throw new Error(t(result.message, { details: result.details }));
        }

        await resetPrivateKey();
        await getAuth();
    };

    handleSelectKey = (cert, signer, resetPrivateKey) => {
        let iteration = 0;

        const execute = () => this.signDataAndLogin(cert, signer, resetPrivateKey).catch((e) => {
            iteration += 1;
            if (iteration <= 3) {
                return execute();
            }
            throw e;
        });

        return execute();
    };

    render() {
        const { setId } = this.props;
        let Layout = DefaultLoginLayout;


        if (['eveteran_public', 'diia'].includes(APP_NAME.toLowerCase())) {
            Layout = NewDiiaLoginLayout;
        }

        if (['grants', 'eco'].includes(APP_NAME.toLowerCase())) {
            Layout = DiiaLoginLayout;
        }

        return (
            <Layout
                setId={setId}
                auth={true}
                onSignHash={this.onSignHash}
                getDataToSign={this.getDataToSign}
                onSelectKey={this.handleSelectKey}
            />
        );
    }
}

LoginPage.propTypes = {
    setId: PropTypes.func,
    dataToSign: PropTypes.string
};

LoginPage.defaultProps = {
    setId: setComponentsId('login'),
    dataToSign: null
};

const mapStateToProps = ({
    authorization,
    eds: { dataToSign }
}) => ({
    ...authorization,
    dataToSign
});

const translated = translate('LoginPage')(LoginPage);
export default connect(mapStateToProps)(translated);
