import React from 'react';
import PropTypes from 'prop-types';
import setComponentsId from 'helpers/setComponentsId';
import {translate} from 'react-translate';
import {Link} from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from 'assets/jss';
import theme from 'themes';

const FullPageLayout = ({classes, children, t, footer, setId}) => (
    <Card
        className={classes.fullPageLayout}
        id={setId('')}
    >
        <CardContent
            className={classes.header}
            id={setId('content')}
        >
            <Link to="/" id={setId('link-logo')}>
                <img className={classes.logo} src={theme.headerImage} style={theme.headerImageStyle} alt={t('TITLE')}/>
            </Link>
        </CardContent>
        <CardContent
            className={classes.body}
            id={setId('content2')}
        >
            {children}
        </CardContent>
        {
            footer ?
                <CardContent
                    className={classes.footer}
                    id={setId('footer')}
                >
                    {footer}
                </CardContent>
            : null
        }
    </Card>
);

FullPageLayout.propTypes = {
    setId:    PropTypes.func,
    classes:  PropTypes.object.isRequired,
    children: PropTypes.node,
    footer:   PropTypes.node,
    t:     PropTypes.func.isRequired
};

FullPageLayout.defaultProps = {
    setId:    setComponentsId('full-page'),
    children: '',
    footer:   ''
};

const styled = withStyles(styles)(FullPageLayout);
export default translate('Layout')(styled);
