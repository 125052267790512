import qs from 'qs';
import getCookie from 'helpers/getCookie';
import setCookie from 'helpers/setCookie';
import deleteCookie from 'helpers/deleteCookie';
import ua from './ua.json';
import en from './en-GB.json';
import uaBPMN from './uaBPMN.json';
import uaDFRR from './uaDFRR.json';
import uaGRANTS from './uaGRANTS.json';
import uaKYIV from './uaKYIV.json';

const translations = {
    'eng': en
};

export const getQueryLangParam = () => {
    const searchString = window.location.search;
    const chosenLanguage = getCookie('lang');

    if (chosenLanguage) return chosenLanguage;

    if (!searchString) return null;

    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const langExists = (Object.keys(params || {}) || []).includes('lang');

    if (!langExists) return null;

    if (langExists) {
        setCookie('lang', params.lang, 1);
        return params.lang;
    }

    deleteCookie('lang');

    return null;
};


const chosenLanguage = getQueryLangParam();

export default (appName) => {

    if (chosenLanguage && translations[chosenLanguage]) {
        return translations[chosenLanguage];
    }

    switch ((appName || '').toLowerCase()) {
        case 'court':
            return ua;
        case 'dfrr':
            return uaDFRR;
        case 'grants':
            return uaGRANTS;
        case 'bpmn_front_kyiv':
            return uaKYIV;
        default:
            return uaBPMN;
    }
};
