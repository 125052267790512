import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import setComponentsId from 'helpers/setComponentsId';
import { translate } from 'react-translate';

import StringElement from 'components/CustomInput/StringElement';

import { Grid, Button, TextField, SnackbarContent, InputAdornment } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Done } from '@mui/icons-material';
import style from 'assets/jss';

import promiseChain from 'helpers/promiseChain';

import config from 'config.json';

import {
    checkPhoneExists,
    sendActivationCodeSMS,
    verifyActivationCodeSMS
} from 'actions/auth';

const regex = /380(\d{9})/g;

const {
    SHOW_PHONE_CONFIRM
} = config;

class PhoneInput extends Component {
    state = {
        checked: this.props.checked,
        showActivation: false,
        code: '',
        value: this.props.value,
        error: this.props.error,
        codeError: null
    };

    componentWillReceiveProps({ error }) {
        if (!this.state.error) {
            this.setState({ error });
        }
    }

    valid = phone => phone.match(regex);

    checkPhoneExists = async () => {
        const { t } = this.props;
        const { value } = this.state;
        const { text } = await checkPhoneExists(value);
        if (text !== 'null') {
            throw t('PHONE_ALREADY_EXISTS');
        }
    };

    checkCodeValid = async () => {
        const { t } = this.props;
        const { code } = this.state;
        if (!code) {
            throw t('EMPTY_CODE_ERROR');
        }
    };

    verifyActivationCode = async () => {
        const { t } = this.props;
        const { value, code } = this.state;
        const result = await verifyActivationCodeSMS(value, code);

        if (result !== 'confirm') {
            throw t('ACTIVATION_CODE_INVALID');
        }
    };

    handleFinish = () => {
        const { onChange, onCodeChange } = this.props;
        const { value, code } = this.state;

        onChange && onChange({ target: { value } }, () => {
            code && onCodeChange && onCodeChange({ target: { value: code } });
        });
    };

    handleNotNow = () => promiseChain([
        this.checkPhoneExists,
        () => this.setState({ checked: true, error: null, showActivation: false, code: '' }, this.handleFinish)
    ]).catch(error => this.setState({ error }));

    handleToggleActivation = () => promiseChain([
        this.checkPhoneExists,
        this.handleSendCode,
        () => this.setState({ showActivation: true })
    ]).catch(error => this.setState({ error }));

    handleChange = ({ target: { value } }) => {
        const { onChange } = this.props;
        this.setState({ checked: false, value, error: null });

        if (!SHOW_PHONE_CONFIRM) {
            onChange && onChange({ target: { value } });
        }
    };

    handleChangeCode = ({ target: { value } }) => this.setState({ code: value, codeError: null });

    handleSendCode = async () => {
        const { t } = this.props;
        const { value } = this.state;
        const result = await sendActivationCodeSMS(value);
        if (result === 'exist') {
            throw t('PHONE_ALREADY_EXISTS');
        }
    };

    handleActivate = () => promiseChain([
        this.checkCodeValid,
        this.verifyActivationCode,
        () => this.setState({ checked: true, showActivation: false }, this.handleFinish)
    ]).catch(error => this.setState({ codeError: error }));

    renderActivation() {
        const { t, classes, setId } = this.props;
        const { code, codeError } = this.state;
        return (
            <Fragment>
                <SnackbarContent
                    className={classes.successSnackbar}
                    message={t('ACTIVATIONS_BY_SMS_TEXT')}
                />
                <Grid
                    container={true}
                    spacing={8}
                    id={setId('container')}
                >
                    <Grid
                        item={true}
                        xs={4}
                        id={setId('grid')}
                    >
                        <TextField
                            variant="standard"
                            name="code"
                            margin="none"
                            value={code}
                            error={!!codeError}
                            helperText={codeError}
                            label={t('ACTIVATION_CODE')}
                            onChange={this.handleChangeCode}
                            className={classes.fullWidth}
                            id={setId('code')}
                            inputProps={{ maxLength: 6 }} />
                    </Grid>
                    <Grid
                        item={true}
                        xs={4}
                        id={setId('grid-2')}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.fullWidth}
                            onClick={this.handleActivate}
                            id={setId('activate-button')}
                            setId={elementName => setId(`activate-button-${elementName}`)}
                        >
                            {t('ACTIVATE')}
                        </Button>
                    </Grid>
                    <Grid
                        item={true}
                        xs={4}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.fullWidth}
                            onClick={this.handleNotNow}
                            id={setId('not-now-button')}
                            setId={elementName => setId(`not-now-button-${elementName}`)}
                        >
                            {t('NOT_NOW')}
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderActivationRequest() {
        const { t, classes, setId } = this.props;
        return (
            <Grid
                container={true}
                spacing={8}
                id={setId('container')}
            >
                <Grid
                    item={true}
                    xs={6}
                    id={setId('grid')}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.fullWidth}
                        onClick={this.handleToggleActivation}
                        id={setId('activate-button')}
                        setId={elementName => setId(`activate-button-${elementName}`)}
                    >
                        {t('ACTIVATE_PHONE')}
                    </Button>
                </Grid>
                <Grid
                    item={true}
                    xs={6}
                    id={setId('grid-2')}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.fullWidth}
                        onClick={this.handleNotNow}
                        id={setId('not-now-button')}
                        setId={elementName => setId(`not-now-button-${elementName}`)}
                    >
                        {t('NOT_NOW')}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    render() {
        const {
            classes,
            name,
            label,
            setId
        } = this.props;

        const {
            value,
            error,
            checked,
            showActivation
        } = this.state;
        const showActivationRequest = this.valid(value) && !checked && !showActivation;

        return (
            <Fragment>
                <StringElement
                    disabled={showActivation || checked}
                    error={error}
                    name={name}
                    label={label}
                    value={value || ''}
                    onChange={this.handleChange}
                    mask="380999999999"
                    setId={elementName => setId(`${name}-${elementName}`)}
                    InputProps={checked ? {
                        endAdornment: (
                            <InputAdornment>
                                <Done className={classes.successIcon} />
                            </InputAdornment>
                        )
                    } : {}}
                />
                {SHOW_PHONE_CONFIRM && showActivationRequest && this.renderActivationRequest()}
                {SHOW_PHONE_CONFIRM && showActivation && this.renderActivation()}
            </Fragment>
        );
    }
}

PhoneInput.propTypes = {
    setId: PropTypes.func,
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
    error: PropTypes.object,
    name: PropTypes.string,
    checked: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,

    onCodeChange: PropTypes.func.isRequired
};

PhoneInput.defaultProps = {
    setId: setComponentsId('phone-input'),
    checked: false,
    error: null,
    value: '',
    name: '',
    label: ''
};

const styled = withStyles(style)(PhoneInput);
export default translate('RegisterForm')(styled);
