import React from 'react';
import PropTypes from 'prop-types';

import { CssBaseline, Hidden } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Header from 'layouts/RightSidebarLayout/Header';
import Footer from 'layouts/RightSidebarLayout/Footer';
import RightSidebar from 'layouts/RightSidebarLayout/RightSidebar';

const styles = theme => ({
    root: {
        flex: 1,
        overflow: 'auto',
        minHeight: '100vh',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            overflow: 'unset'
        }
    },
    wrapper: {
        overflow: 'unset',
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse',
        minHeight: '100vh',
        maxWidth: 1400,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            backgroundColor: '#fff'
        }
    },
    sidebarWrapper: {
        height: '100%',
        width: 400,
        flexShrink: 0
    },
    appContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'unset',
        marginLeft: 0,
        transition: 'margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '32px 160px 80px 80px',
        [theme.breakpoints.down('md')]: {
            padding: '20px',
        }
    },
    toolbar: {
        backgroundColor: theme.leftSidebarBg,
        padding: 6
    },
    collapseButton: {
        padding: 5,
        minWidth: 5
    },
    onboarding: {
        maxWidth: 928,
        margin: '0 auto',
        marginTop: 22,
        [theme.breakpoints.down('md')]: {
            padding: 0
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            background: '#fff',
            margin: 0
        }
    },
    button: {
        lineHeight: '3em',
        color: '#000',
        cursor: 'pointer'
    },
    imageSrc: {
        width: 48,
        height: 48,
        display: 'block',
        margin: 2
    },
    logos: {
        display: 'flex',
        position: 'relative',
        top: 78,
        right: 32
    },
    grow: {
        flexGrow: 1
    },
    onboardingbg: {
        backgroundColor: '#E7EEF3'
    },
    childrenWrapp: {
        position: 'relative'
    }
});

const RightSidebarLayout = ({ classes, children, details }) => (
    <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Hidden lgDown={true} implementation="css">
                    <RightSidebar details={details} />
                </Hidden>
                <div className={classes.appContent}>
                    <Header />
                    <div className={classes.contentContainer}>
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </React.Fragment>
);

RightSidebarLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    disableScrolls: PropTypes.bool,
    openSidebar: PropTypes.bool
};

RightSidebarLayout.defaultProps = {
    disableScrolls: false,
    openSidebar: null
};

export { default as DawerContent } from './DawerContent';
export default withStyles(styles)(RightSidebarLayout);
