import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import setComponentsId from 'helpers/setComponentsId';

import { Link } from 'react-router-dom';
import { translate } from 'react-translate';
import { connect } from 'react-redux';

import theme from 'themes';

import Ajv from 'ajv';

import {
    FormControl,
    FormLabel,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    Typography,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Info } from '@mui/icons-material';

import { fourteenYearsAgo, today } from 'helpers/humanDateFormat';
import normalizeErrors from 'helpers/normalizeErrors';

import CustomDatePicker from 'components/CustomInput/CustomDatePicker';

import style from 'assets/jss';

import { legalSchema, personSchema } from 'variables/validateSchemas';

import PhoneInput from './PhoneInput';
import EmailInput from './EmailInput';

import config from 'config.json';

const {
    SHOW_PHONE,
    EMAIL_OPTIONAL
} = config;

const ajv = new Ajv({ ownProperties: true, allErrors: true });

class RegisterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: this.props.errors,
            values: {
                ...(this.props.values || {}),
                agreement: theme.hideTermsLink
            }
        };
    }

    handleChange = name => ({ target: { value } }, callback) => this.setState({
        values: {
            ...this.state.values,
            [name]: value
        },
        errors: {
            ...this.state.errors,
            [name]: null
        }
    }, callback);

    handleCheck = name => ({ target: { checked } }) =>
        this.handleChange(name)({ target: { value: checked } });

    onDateChange = name => date =>
        this.handleChange(name)({ target: { value: date } });

    handleSubmit = (e) => {
        e.preventDefault();

        const { t, onSubmit } = this.props;
        const { values } = this.state;
        const { isLegal } = values;

        const validator = ajv.compile(isLegal ? legalSchema : personSchema);
        validator(values);

        const errors = normalizeErrors(validator.errors || [], t);

        if (!SHOW_PHONE) delete errors.phone;
        if (EMAIL_OPTIONAL) delete errors.email;

        this.setState({ errors });
        if (!Object.keys(errors).length) {
            onSubmit && onSubmit(values);
        }
    };

    handleDiscard = () => {
        window.location.href = '/logout';
    };

    renderTextField = (name, rest) => {
        const { t } = this.props;
        const { values, errors } = this.state;
        return (
            <TextField
                variant="standard"
                {...rest}
                name={name}
                error={!!(errors || {})[name]}
                helperText={(errors || {})[name] || ''}
                label={t(name.toUpperCase() + '_INPUT_LABEL')}
                value={(values || '')[name] || ''}
                onChange={this.handleChange(name)}
                margin="normal" />
        );
    };

    renderName = () => {
        const { values: { last_name, first_name, middle_name } } = this.state;

        return (
            <FormControl variant="standard" fullWidth={true}>
                <Typography variant="h5" component="h3">
                    {(last_name || '') + ' ' + (first_name || '') + ' ' + (middle_name || '')}
                </Typography>
            </FormControl>
        );
    };

    renderPhoneField = () => {
        const { t, setId } = this.props;
        const { errors, values: { phone } } = this.state;

        if (!SHOW_PHONE) return null;

        return (
            <PhoneInput
                name="phone"
                error={(errors || {}).phone}
                label={t('PHONE_INPUT_LABEL')}
                value={phone || ''}
                onChange={this.handleChange('phone')}
                onCodeChange={this.handleChange('code_phone')}
                setId={elementName => setId(`phone-${elementName}`)}
            />
        );
    };

    renderEmailField = () => {
        const { t, setId } = this.props;
        const { errors, values: { email } } = this.state;
        return (
            <EmailInput
                name="email"
                error={(errors || {}).email}
                label={t('EMAIL_INPUT_LABEL')}
                value={email || ''}
                onChange={this.handleChange('email')}
                onCodeChange={this.handleChange('code_email')}
                setId={elementName => setId(`email-${elementName}`)}
            />
        );
    };

    renderLegalDateRegistrationField = () => {
        const { t, setId } = this.props;
        const { errors, values: { legalEntityDateRegistration } } = this.state;

        return (
            <CustomDatePicker
                name="birthday"
                error={(errors || {}).legalEntityDateRegistration}
                label={t('REG_DATE_INPUT_LABEL')}
                margin="normal"
                incomingFormat="DD/MM/YYYY"
                onChange={this.onDateChange('legalEntityDateRegistration')}
                date={legalEntityDateRegistration || ''}
                minDate="01/01/1900"
                maxDate={today('DD/MM/YYYY')}
                setId={elementName => setId(`birthday-${elementName}`)}
            />
        );
    };

    renderBirthdayField = () => {
        const { t, setId } = this.props;
        const { errors, values: { birthday } } = this.state;

        return (
            <CustomDatePicker
                name="birthday"
                error={(errors || {}).birthday}
                label={t('BIRTHDAY_INPUT_LABEL')}
                margin="normal"
                incomingFormat="DD/MM/YYYY"
                onChange={this.onDateChange('birthday')}
                date={birthday || ''}
                minDate="01/01/1900"
                maxDate={fourteenYearsAgo('DD/MM/YYYY')}
                setId={elementName => setId(`birthday-${elementName}`)}
            />
        );
    };

    renderCheckboxField = (name) => {
        const { t } = this.props;
        const { values } = this.state;
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!(values || '')[name]}
                        onChange={this.handleCheck(name)}
                        color="primary"
                    />
                }
                label={t(name.toUpperCase() + '_INPUT_LABEL')}
            />
        );
    };

    renderLegalForm() {
        return (
            <Fragment>
                {this.renderTextField('companyName', { disabled: true })}
                {theme.useDateRegistration && this.renderLegalDateRegistrationField()}
                {this.renderTextField('edrpou', { disabled: true })}
                {this.renderPhoneField()}
                {this.renderEmailField()}
            </Fragment>
        );
    }

    renderPersonForm() {
        const { classes } = this.props;
        return (
            <Fragment>
                {/* {this.renderTextField('last_name', { disabled: true })}
                {this.renderTextField('first_name', { disabled: true })}
                {this.renderTextField('middle_name', { disabled: true })} */}
                {this.renderName()}
                {theme.useBirthday && this.renderBirthdayField()}
                {this.renderTextField('ipn', { disabled: true, className: classes.ipn })}
                {theme.useIndividualEntrepreneur && this.renderCheckboxField('isIndividualEntrepreneur')}
                {this.renderPhoneField()}
                {!theme?.hiddenEmail && this.renderEmailField()}
            </Fragment>
        );
    }

    render() {
        const { t, classes, setId } = this.props;
        const { values, errors } = this.state;

        const { isLegal, agreement } = values || {};

        return (
            <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.formControl}
                id={setId('')}>
                {isLegal ?
                    this.renderLegalForm() :
                    this.renderPersonForm()
                }
                {theme.useFAQLink &&
                    <a
                        href="https://wiki.court.gov.ua/pages/viewpage.action?pageId=8126525&moved=true"
                        target="_blank"
                        id={setId('link-to-faq')}
                        className={classes.faqLink}
                        rel="noopener noreferrer"
                    >
                        <Button
                            color="inherit"
                            className={classes.minButton}
                            id={setId('link-to-faq-button')}
                        >
                            <Info />
                            {t('FAQ_LINK')}
                        </Button>
                    </a>
                }
                <FormControl
                    variant="standard"
                    required={true}
                    error={errors.agreement}
                    component="fieldset"
                    className={classes.formControl}
                    id={setId('control-agreement')}>
                    {!!errors.agreement &&
                        <FormLabel id={setId('label-agreement')}>
                            {t('AGREEMENT_REQUIRED')}
                        </FormLabel>
                    }
                    {theme.hideTermsLink ? null : (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={agreement || false}
                                    onChange={this.handleCheck('agreement')}
                                    id={setId('checkbox-agreement')}
                                />
                            }
                            label={t('AGREEMENT_TEXT', {
                                link: (
                                    <Link
                                        to="/terms"
                                        target="_blank"
                                        id={setId('link-to-terms')}
                                    >
                                        {t('TERMS_LINK')}
                                    </Link>
                                )
                            })}
                        />
                    )}
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.fullWidth}
                    onClick={this.handleSubmit}
                    id={setId('activate-button')}
                    setId={elementName => setId(`activate-button-${elementName}`)}
                >
                    {t('ACTIVATE')}
                </Button>
                <Button
                    className={classes.fullWidth}
                    onClick={this.handleDiscard}
                    color="inherit"
                    id={setId('discard-button')}
                    setId={elementName => setId(`discard-button-${elementName}`)}
                >
                    {t('DISCARD')}
                </Button>
            </FormControl>
        );
    }
}

RegisterForm.propTypes = {
    setId: PropTypes.func,
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object,
    values: PropTypes.object,
    onSubmit: PropTypes.func
};

RegisterForm.defaultProps = {
    setId: setComponentsId('left-side-bar'),
    errors: {},
    values: {},
    onSubmit: undefined
};

const styled = withStyles(style)(RegisterForm);
const translated = translate('RegisterForm')(styled);

const mapStateToProps = () => ({});

// decorate and export
export default connect(mapStateToProps)(translated);
