import React from 'react';
import QRCode from 'qrcode.react';
import MobileDetect from 'mobile-detect';
import { CardContent, CircularProgress, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { translate } from 'react-translate';

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: 120,
        color: 'green'
    },
    error: {
        fontSize: 120,
        color: 'red'
    },
    container: {
        height: 256,
        width: 256,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 'unset',
            width: 'unset',
            display: 'flex',
            justifyContent: 'center'
        }
    },
    progress: {
        margin: 'auto'
    },
    disabled: {
        opacity: 0.25
    }
}));

const md = new MobileDetect(window.navigator.userAgent);

const SigningResult = ({ finished, error, loading, deepLink, t }) => {
    const classes = useStyles();
    const isMobile = !!md.mobile();

    return (
        <CardContent className={classes.container}>
            {finished ? (
                <CheckRoundedIcon className={classes.icon} />
            ) : (
                    <>
                        {error ? (
                            <ErrorOutlineIcon className={classes.error} />
                        ) : (
                                <>
                                    {loading ? <CircularProgress className={classes.progress} /> : null}
                                    {deepLink ? (
                                        <>
                                            {
                                                isMobile ?
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => { window.location.href = deepLink; }}
                                                    >
                                                        {t('OpenQrButton')}
                                                    </Button>
                                                : <QRCode size={256} value={deepLink} />
                                            }
                                        </>
                                    ) : null}
                                </>
                            )}
                    </>
                )}
        </CardContent>
    );
};

export default translate('SignForm')(SigningResult);
