import {applyMiddleware, createStore} from 'redux';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import config from './../config.json';

const {APP_ENV: environment} = config;

export default function configureStore() {
        if (environment !== 'production') {
            return createStore(rootReducer, applyMiddleware(createLogger({ collapsed: true })));
        }
        return createStore(rootReducer);
}
