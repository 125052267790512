import React, { Component } from 'react';
import PropTypes from 'prop-types';
import setComponentsId from 'helpers/setComponentsId';
import { connect } from 'react-redux';

import { getAuth } from 'actions/auth';
// import { requestEDSServerList } from 'actions/eds';

import { translate } from 'react-translate';

import LoginPage from 'pages/Login';
import RegisterPage from 'pages/Register';
import TwoFactorAuth from 'pages/TwoFactorAuth';
import BlockScreen from 'components/BlockScreen';
import EmptyPage from 'components/EmptyPage';
import withStyles from '@mui/styles/withStyles';

// import promiseChain from 'helpers/promiseChain';

import config from 'config.json';

const { BACKEND_URL } = config;

const styles = {
    wrap: {
        paddingTop: 64,
        paddingLeft: 260,
        '@media (max-width: 959px)': {
            paddingLeft: 0
        }
    }
};

class App extends Component {
    state = { ready: false };

    componentWillMount = () => {
        getAuth().then(() => this.setState({ ready: true })).catch(() => null);
    };

    componentWillReceiveProps = ({ redirect }) => {
        if (redirect) {
            const redirectURL =
                BACKEND_URL +
                (BACKEND_URL.charAt(BACKEND_URL.length - 1) !== '/' ? '/' : '') +
                redirect.split('/').filter(Boolean).join('/');
            document.location.href = redirectURL;
        }
    };

    render() {
        const { provider, user, redirect, info, twoFactorAuthNeeded, setId, DBError, classes, t } = this.props;
        const { ready } = this.state;
        
        if (DBError) {
            return (
                <div className={classes.wrap}>
                    <EmptyPage
                        title={t('ERROR')}
                        description={t('ERROR_DESCRIPTION')}
                    />
                </div>
            );
        }

        if (!ready || redirect) {
            return <BlockScreen open={true} />;
        }

        if (info && twoFactorAuthNeeded) {
            return (
                <TwoFactorAuth
                    values={info}
                    setId={elementName => setId(`two-factor-${elementName}`)}
                />
            );
        }

        if (provider && user && (ready || !redirect)) {
            return (
                <RegisterPage
                    values={user}
                    setId={elementName => setId(`register-${elementName}`)}
                />
            );
        }

        return <LoginPage setId={elementName => setId(`login-${elementName}`)} />;
    }
}

App.propTypes = {
    setId: PropTypes.func,
    provider: PropTypes.object,
    user: PropTypes.object,
    info: PropTypes.object,
    redirect: PropTypes.string,
    twoFactorAuthNeeded: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    DBError: PropTypes.bool.isRequired
};

App.defaultProps = {
    setId: setComponentsId('app'),
    provider: null,
    user: null,
    info: null,
    redirect: '',
    twoFactorAuthNeeded: false
};

const translated = translate('App')(App);

export default connect(({ auth }) => (auth))(withStyles(styles)(translated));
