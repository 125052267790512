import React from 'react';
import ReactDOM from 'react-dom';
import { TranslatorProvider } from 'react-translate';
import { Provider } from 'react-redux';

import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import 'assets/css/material-dashboard-react.css';
import 'assets/css/material-dashboard-react-elements.css';

import indexRoutes from 'routes';
import translations from 'variables/translations';

import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Auth from 'components/Auth';
import store from 'store';

// import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/browser';
// import GoogleTagManager from 'components/GoogleTagManager';

import config from 'config.json';
import theme from 'themes';
import packageJson from './../package.json';

import 'assets/css/main.css';

const { APP_ENV, APP_NAME, APP_TITLE, /*HOTJAR_ID, HOTJAR_SV,*/ SENTRY_DSN } = config;
const { name, version } = packageJson;

document.title = APP_TITLE;

// if (HOTJAR_ID && HOTJAR_SV) {
//     hotjar.initialize(HOTJAR_ID, HOTJAR_SV);
// }

if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: APP_ENV,
        release: `${name}@${version}`,
        ignoreErrors: ['401 unauthorized', '404 not found', 'NetworkError when attempting to fetch resource.', 'Failed to fetch', 'Перелік закінчено', 'NS_BINDING_ABORTED:']
    });
}

const hist = createBrowserHistory();

const createRoute = (prop, key) => {
    const { redirect, to } = prop;
    if (redirect) {
        return (<Redirect {...{ to, key }} />);
    }
    return (<Route exact={true} {...prop} key={key} />);
};

const router = (
    //  <GoogleTagManager>
    <Router history={hist}>
        <Switch>{indexRoutes.map(createRoute)}</Switch>
    </Router>
    //  </GoogleTagManager>
);

const auth = (
    <TranslatorProvider translations={translations(APP_NAME)}>
        <Auth>
            {router}
        </Auth>
    </TranslatorProvider>
);

const storeProvider = (
    <Provider store={store}>{auth}</Provider>
);

const themeProvider = <StyledEngineProvider injectFirst>
    <ThemeProvider theme={createTheme(adaptV4Theme(theme))}>{storeProvider}</ThemeProvider>
</StyledEngineProvider>;

ReactDOM.render((<LocalizationProvider
    dateAdapter={AdapterDayjs}
    adapterLocale={'uk'}
>
    {themeProvider}
</LocalizationProvider>), document.getElementById('root'));

