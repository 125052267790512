import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import classNames from "classnames";
import { Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Layout from "layouts/RightSidebarLayout";
import DiiaSignForm from "components/EDSForm/DiiaSignForm";
import FileKeySignForm from "components/EDSForm/FileKeySignForm";
import HardwareKeySignForm from "components/EDSForm/HardwareKeySignForm";
import config from "config";
import ChevronRight from "assets/img/logo/right-chevron-big.svg";
import NewGovIdButton from "./NewGovIdButton";
const { GOV_ID, APP_NAME } = config;

const styles = (theme) => ({
  mainTitle: {
    display: "block",
    fontSize: "42px",
    lineHeight: "48px",
    marginBottom: "48px",
    [theme.breakpoints.down('md')]: {
      fontSize: "24px",
      lineHeight: "26px",
    },
  },
  mainTitleVetaran: {
    fontSize: 38,
    lineHeight: "40px",
    marginBottom: "43px",
    [theme.breakpoints.down('md')]: {
      fontSize: "24px",
      lineHeight: "26px",
    },
  },
  title: {
    fontSize: "24px",
    lineHeight: "32px",
    marginTop: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down('md')]: {
      marginTop: "20px",
      marginBottom: "20px",
      fontSize: "24px",
      lineHeight: "26px",
    },
  },
  subtitle: {
    marginTop: 32,
    marginBottom: 32,
    fontSize: "36px",
    lineHeight: "40px",
    [theme.breakpoints.down('md')]: {
      fontSize: "28px",
      lineHeight: "30px",
    },
  },
  headline: {
    marginBottom: 32,
    fontSize: "24px",
    lineHeight: "28px",
  },
  subheading: {
    marginBottom: 18,
  },
  container: {
    display: "flex",
    [theme.breakpoints.down('md')]: {
      display: "block",
    },
  },
  containerVetaran: {
    display: "block",
  },
  item: {
    cursor: "pointer",
    maxWidth: 205,
    padding: 2,
    marginRight: 20,
    position: "relative",
    background: "#000",
    backgroundSize: "200% 300%",
    "&:hover": {
      animation: "10s infinite granimate",
      boxShadow: "none",
      transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      background: "#fff",
      backgroundImage:
        "linear-gradient(217deg,rgba(255,0,0,.8),rgba(255,0,0,0) 70.71%),linear-gradient(127deg,rgba(0,0,255,.8),rgba(0,0,255,0) 70.71%),linear-gradient(336deg,rgba(0,255,0,.8),rgba(0,255,0,0) 70.71%)",
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 10,
      maxWidth: "100%",
    },
  },
  itemContent: {
    padding: 16,
    background: "#fff",
    display: "flex",
  },
  itemTitle: {
    fontSize: "18px",
    lineHeight: "24px",
  },
  grow: {
    flexGrow: 1,
  },
  itemVetaran: {
    maxWidth: 'unset',
    marginBottom: 26,
    marginRight: 0,
  },
  itemContentVetaran: {
    padding: 32,
    height: '100%',
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    minHeight: 137,
  },
  itemTitleVeteran: {
    fontSize: 28,
    lineHeight: "32px",
  },
  itemDescription: {
    marginTop: 10,
    fontSize: 18,
  }
});

const DiiaLoginLayout = ({ t, classes, setId, ...rest }) => {
  const [fileSign, setFileSign] = React.useState(false);
  const [hardwareSign, setHardwareSign] = React.useState(false);
  const [diiaSign, setDiiaSign] = React.useState(false);
  const [busy, setBusy] = React.useState(false);

  const removeActiveItem = () => {
    setFileSign(false);
    setHardwareSign(false);
    setDiiaSign(false);
  };

  const isVeteran = APP_NAME === "eveteran_public";

  const IconComponent = isVeteran ? <img src={ChevronRight} alt="ChevronRight" /> : <ArrowForwardIcon />;

  return (
    <Layout
      setId={setId}
      details={
        <React.Fragment>
          <Typography variant="headline" className={classes.headline}>
            {t("HELPER_TITLE")}
          </Typography>
          <Typography variant="subheading" className={classes.subheading}>
            {t("HELPER_SUBTITLE1")}
          </Typography>
          <Typography variant="subheading" className={classes.subheading}>
            {t("HELPER_SUBTITLE2")}
          </Typography>
          <Typography variant="subheading" className={classes.subheading}>
            {t("HELPER_SUBTITLE3")}
          </Typography>
        </React.Fragment>
      }
    >
      {diiaSign ? (
        <DiiaSignForm
          {...rest}
          setBusy={setBusy}
          diia={true}
          removeActiveItem={removeActiveItem}
        />
      ) : fileSign ? (
        <FileKeySignForm
          {...rest}
          diia={true}
          busy={busy}
          setBusy={setBusy}
          setId={(elementName) => setId(`file-key-${elementName}`)}
          removeActiveItem={removeActiveItem}
        />
      ) : hardwareSign ? (
        <HardwareKeySignForm
          {...rest}
          busy={busy}
          setBusy={setBusy}
          setId={(elementName) => setId(`hardware-key-${elementName}`)}
          diia={true}
          removeActiveItem={removeActiveItem}
        />
      ) : (
        <>
          <Typography
            variant="headline"
            gutterBottom={true}
            id={setId("mainTitle")}
            className={classNames({
              [classes.mainTitle]: true,
              [classes.mainTitleVetaran]: isVeteran,
            })}
          >
            {t(isVeteran ? "DiiaTitleEvetaran" : 'DiiaTitle')}
          </Typography>

          {GOV_ID ? <NewGovIdButton isVeteran={isVeteran} /> : null}

          {isVeteran ? null : (
            <Typography
              variant="headline"
              gutterBottom={true}
              id={setId("title")}
              className={classes.title}
            >
              {t(GOV_ID ? "OtherAuthMethods" : "TITLE")}
            </Typography>
          )}

          <div
            className={classNames({
              [classes.container]: true,
              [classes.containerVetaran]: isVeteran,
            })}
          >
            {
              config.diiaSign ? (
                <div
                  className={classNames({
                    [classes.item]: true,
                    [classes.itemVetaran]: isVeteran,
                  })}
                  onClick={() => setDiiaSign(true)}
                >
                  <div
                    className={classNames({
                      [classes.itemContent]: true,
                      [classes.itemContentVetaran]: isVeteran,
                    })}
                  >
                    <div>
                      <Typography
                        className={classNames({
                          [classes.itemTitle]: true,
                          [classes.itemTitleVeteran]: isVeteran,
                        })}
                      >
                        {t("DiiaSignMethod")}
                      </Typography>
    
                      {
                        isVeteran ? (
                          <Typography className={classes.itemDescription}>
                            {t("DiiaSignMethodDescription")}
                          </Typography>
                        ) : <div className={classes.grow} />
                      }
                    </div>
                    {IconComponent}
                  </div>
                </div>
              ) : null
            }

            <div
              className={classNames({
                [classes.item]: true,
                [classes.itemVetaran]: isVeteran,
              })}
             onClick={() => setFileSign(true)}
            >
              <div
                className={classNames({
                  [classes.itemContent]: true,
                  [classes.itemContentVetaran]: isVeteran,
                })}
              >
                <Typography
                  className={classNames({
                    [classes.itemTitle]: true,
                    [classes.itemTitleVeteran]: isVeteran,
                  })}
                >
                  {t("FileKeySignMethod")}
                </Typography>
                <div className={classes.grow} />
                {IconComponent}
              </div>
            </div>

            <div
              className={classNames({
                [classes.item]: true,
                [classes.itemVetaran]: isVeteran,
              })}
              onClick={() => setHardwareSign(true)}
            >
              <div
                className={classNames({
                  [classes.itemContent]: true,
                  [classes.itemContentVetaran]: isVeteran,
                })}
              >
                <Typography
                  className={classNames({
                    [classes.itemTitle]: true,
                    [classes.itemTitleVeteran]: isVeteran,
                  })}
                >
                  {t("HardwareKeySignMethod")}
                </Typography>
                <div className={classes.grow} />
                {IconComponent}
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

DiiaLoginLayout.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
  setId: PropTypes.func,
  onSelectKey: PropTypes.func,
  diiaSign: PropTypes.bool,
};

DiiaLoginLayout.defaultProps = {
  setId: () => null,
  onSelectKey: () => null,
  diiaSign: true,
};

const styled = withStyles(styles)(DiiaLoginLayout);
export default translate("LoginPage")(styled);
