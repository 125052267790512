import React from 'react';
import PropTypes from 'prop-types';
import setComponentsId from 'helpers/setComponentsId';
import { translate } from 'react-translate';
import { Link } from 'react-router-dom';

import { Card, CardContent, Grid, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import styles from 'assets/jss';
import theme from 'themes';

const LeftSidebarLayout = ({ classes, t, children, setId }) => (
    <Card
        className={classes.leftSidebarLayout}
        id={setId('')}
    >
        <Grid
            container={true}
            id={setId('grid')}
        >
            <Grid
                item={true}
                xs={12}
                sm={5}
                id={setId('content-wrap')}
            >
                <CardContent
                    className={classes.header}
                    id={setId('header-content')}
                >
                    <Link
                        to="/"
                        id={setId('link-logo')}
                        className={classes.logoLink}
                    >
                        <img
                            className={classes.logo}
                            src={theme.LeftSidebarLogo || theme.headerImage}
                            style={theme.headerImageStyle}
                            alt={t('TITLE')}
                        />
                        {
                            theme.logoTitle ? (
                                <Typography
                                    component="div"
                                    className={classes.logoTitle}
                                    style={theme.logoTitleStyle}
                                >
                                    {theme.logoTitle}
                                </Typography>
                            ) : null
                        }
                    </Link>
                    <Typography
                        component="div"
                        variant="subheading"
                        gutterBottom={true}
                        className={classes.subheader}
                        style={theme.registerTitleStyle}
                        id={setId('title')}
                    >
                        {theme.registerInfoIcon ? <div style={theme.registerInfoIcon} /> : null}
                        {t('REGISTER_TITLE')}
                    </Typography>
                </CardContent>
            </Grid>
            <Grid
                item={true}
                xs={12}
                sm={7}
                id={setId('grid-2')}
            >
                <CardContent
                    className={classes.body}
                    id={setId('content')}
                >
                    {children}
                </CardContent>
            </Grid>
        </Grid>
    </Card>
);

LeftSidebarLayout.propTypes = {
    setId: PropTypes.func,
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    children: PropTypes.node
};

LeftSidebarLayout.defaultProps = {
    setId: setComponentsId('left-side-bar'),
    children: ''
};

const styled = withStyles(styles)(LeftSidebarLayout);
export default translate('Layout')(styled);
