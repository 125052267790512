import theme from 'themes';

import customInputStyle from './components/customInputStyle';

export default {
    ...customInputStyle,
    topHeaderLayout: {
        minWidth: 275,
        maxWidth: 600,
        margin: 'auto'
    },
    leftSidebarLayout: {
        minWidth: 275,
        maxWidth: 840,
        margin: 'auto'
    },
    fullPageLayout: {
        minWidth: 275,
        maxWidth: 1000,
        margin: 'auto'
    },
    header: {
        background: '#00224e',
        color: '#ffffff',
        height: '100%'
    },
    footer: {
        background: '#fffce5'
    },
    body: {
        background: '#ffffff'
    },
    logo: {
        maxWidth: 200
    },
    videoHelpBtn: {
        float: 'right',
        color: '#ffffff',
        opacity: 0.75,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        fontSize: 14
    },
    subheader: {
        color: '#ffffff',
        marginTop: 20,
       // padding: '0px 30px'
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    floatRight: {
        float: 'right'
    },
    fullWidth: {
        width: '100%',
        marginBottom: '10px'
    },
    successSnackbar: {
        backgroundColor: '#43a047',
        boxShadow: 'none',
        padding: '0px 12px',
        marginBottom: '5px'
    },
    successIcon: {
        color: '#43a047'
    },
    minButton: {
        padding: '10px 10px 10px 0'
    },
    '@media (min-width: 840px)': {
        topHeaderLayout: {
            margin: '20px auto'
        },
        leftSidebarLayout: {
            margin: '20px auto'
        },
        fullPageLayout: {
            margin: '20px auto'
        }
    },
    dialog: {
        '& > :last-child': {
            maxWidth: '610px',

            ['@media (max-width:767px)']: { // eslint-disable-line no-useless-computed-key
                margin: '48px 10px'
            }
        }
    },
    dialogContentWrappers: {
        ['@media (max-width:767px)']: { // eslint-disable-line no-useless-computed-key
            padding: '24px 15px 20px'
        }
    },
    videoLink: {
        display: 'flex',
        height: '24px',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        ['@media (max-width:767px)']: { // eslint-disable-line no-useless-computed-key
            position: 'absolute',
            right: 0,
            top: 0
        }
    },
    videoLinkText: {
        ['@media (max-width:767px)']: { // eslint-disable-line no-useless-computed-key
            display: 'none'
        }
    },
    videoIcon: {
        opacity: '0.5'
    },
    videoFrame: {
        width: '560px',
        height: '315px',
        ['@media (max-width:767px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%'
        }
    },
    smallButton: {
        margin: 0,
        padding: 0,
        minHeight: 26,
        minWidth: 26
    },
    logoTitle: {
        color: '#fff',
        fontSize: 20,
        lineHeight: '24px',
        letterSpacing: 1.1,
        textAlign: 'center',
        paddingTop: 15,
    },
    logoLink: {
        textDecoration: 'none!important'
    },
    ...theme.overrides
};
