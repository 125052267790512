import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import generatePassword from "password-generator";
import { Typography, Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import logoBankID from "assets/img/logo_BankID.svg";
import BlockScreen from "components/BlockScreen";
import * as api from "services/api";
import store from "store";
import ChevronRight from "assets/img/logo/right-chevron-big.svg";

const styles = (theme) => ({
  root: {
    maxWidth: 655,
    padding: 32,
    backgroundColor: "#E7EEF3",
  },
  title: {
    fontSize: "38px",
    lineHeight: "40px",
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: "20px",
      marginBottom: "20px",
      fontSize: "18px",
      lineHeight: "24px",
    },
  },
  subtitle: {
    marginBottom: 32,
    fontSize: "16px",
    lineHeight: "24px",
    [theme.breakpoints.down('md')]: {
      fontSize: "13px",
      lineHeight: "16px",
    },
  },
  bankLogo: {
    height: 40,
    paddingLeft: 5,
    verticalAlign: "text-bottom",
    [theme.breakpoints.down('lg')]: {
      height: 30,
    },
    [theme.breakpoints.down('sm')]: {
      height: 25,
    },
  },
  button: {
    "& > span:first-child": {
      [theme.breakpoints.down('md')]: {
        padding: "16px 32px",
      },
    },
  },
  errorTextWrapper: {
    display: "flex",
    marginBottom: 20,
    padding: "25px 18px",
    backgroundColor: "#fff7e3",
  },
  icon: {
    fontSize: 38,
    marginRight: 10,
  },
  errorText: {
    fontSize: 16,
    fontWeight: "300",
    lineHeight: "24px",
  },
  item: {
    cursor: "pointer",
    minHeight: 135,
    padding: 2,
    marginBottom: 26,
    position: "relative",
    background: "#000",
    backgroundSize: "200% 300%",
    "&:hover": {
      animation: "10s infinite granimate",
      boxShadow: "none",
      transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      background: "#fff",
      backgroundImage:
        "linear-gradient(217deg,rgba(255,0,0,.8),rgba(255,0,0,0) 70.71%),linear-gradient(127deg,rgba(0,0,255,.8),rgba(0,0,255,0) 70.71%),linear-gradient(336deg,rgba(0,255,0,.8),rgba(0,255,0,0) 70.71%)",
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 10,
      maxWidth: "100%",
    },
  },
  itemContent: {
    padding: 32,
    height: '100%',
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    "& > img": {
      width: 29,
    },
  },
  itemTitle: {
    fontSize: 28,
    lineHeight: "32px",
  },
  itemDescription: {
    marginTop: 10,
    fontSize: 18,
  }
});

const st = localStorage.getItem("state");

const NewGovIdButton = ({ t, classes, isVeteran }) => {
  const [error, setError] = React.useState();
  const [preloader, setPreloader] = React.useState(false);

  React.useEffect(() => {
    if (!st) {
      const signa = generatePassword(20, false);
      localStorage.setItem("state", signa);
    }

    const params = window.location.search;

    if (!params.length) return;

    const param = params.replace("?", "").split("&");

    const newState = {};

    param.forEach((item) => {
      const paramName = item.split(/=(.+)/)[0];
      const paramValue = item.split(/=(.+)/)[1];

      newState[paramName] = paramValue;
    });

    const { code, state } = newState;

    const data = {
      code,
      state,
    };

    const sign = localStorage.getItem("state");

    if (sign !== state) return;

    setPreloader(true);

    api
      .post("authorise/govid", data, "SET_GOV_ID", store.dispatch)
      .then((result) => {
        console.log("RESULT", result);
        if (result instanceof Error) {
          setPreloader(false);
          setError(result);
          return null;
        } else {
          window.location.reload();
          localStorage.removeItem("state");
        }
      });
  }, []);

  const handleClick = () => {
    setPreloader(true);

    const signature = localStorage.getItem("state");

    api
      .get(
        `authorise/govid/info?state=${signature}`,
        "GET_GOV_ID",
        store.dispatch
      )
      .then((result) => window.location.replace(result.totalAuthUrl))
      .catch(() => setPreloader(false));
  };

  return (
    <>
      {preloader ? <BlockScreen open={true} /> : null}
      {error ? (
        <div className={classes.diiaDisclaimer}>
          <div className={classes.errorTextWrapper}>
            <span role="img" aria-label="emoji" className={classes.icon}>
              🤷‍♂
            </span>
            <Typography variant="headline" className={classes.errorText}>
              {t("NOIPN")} {t("NOIPN_DESCRIPTION")}
            </Typography>
          </div>
        </div>
      ) : null}
      {
        isVeteran ? (
          <div className={classes.item} onClick={handleClick}>
            <div className={classes.itemContent}>
              <div>
                <Typography className={classes.itemTitle}>
                  {t("BankIdAuthEvetaran")}
                </Typography>
                <Typography className={classes.itemDescription}>
                  {t("EVeteranBankIdDescription")}
                </Typography>
              </div>
              <img src={ChevronRight} alt="ChevronRight" />
            </div>
          </div>
        ) : (
          <div className={classes.root}>
            <Typography gutterBottom className={classes.title}>
              {t("BankIdAuth")}
              <img className={classes.bankLogo} src={logoBankID} alt="LogoBankID" />
            </Typography>
            <Typography variant="body2" component="p" className={classes.subtitle}>
              {t("BankIdText")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              className={classes.button}
            >
              {t("Enter")}
            </Button>
          </div>
        )
      }
    </>
  );
};

NewGovIdButton.propTypes = {
  t: PropTypes.func.isRequired,
  isVeteran: PropTypes.bool,
};

NewGovIdButton.defaultProps = {
  isVeteran: false
};

const styled = withStyles(styles)(NewGovIdButton);
export default translate("LoginPage")(styled);
