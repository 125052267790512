import React from 'react';
import PropTypes from 'prop-types';
import setComponentsId from 'helpers/setComponentsId';
import {translate} from 'react-translate';
import Layout from 'layouts/fullPage';
import renderHTML from 'react-render-html';

const Terms = ({ setId, t }) => (
    <Layout setId={setId}>
        <div>
            {renderHTML(t('TERMS_BODY'))}
        </div>
    </Layout>
);

Terms.propTypes = {
    setId: PropTypes.func,
    t: PropTypes.func.isRequired
};

Terms.defaultProps = {
    setId: setComponentsId('terms')
};

export default translate('Terms')(Terms);
