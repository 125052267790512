import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import moment from "moment";
import { Typography, ListItemText, Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import classNames from "classnames";
import diiaLogo from "assets/img/diia-logo-invert.svg";
import uaSign from "assets/img/uasign-invert.svg";
import facebook from "assets/img/facebook-invert.svg";
import instagram from "assets/img/instagram-invert.svg";
import telegram from "assets/img/telegram-invert.svg";
import viber from "assets/img/viber-invert.svg";
import grantsLogo from "assets/img/logo/logo-footer.svg";

import config from 'config.json';

const { APP_NAME } = config;

const styles = (theme) => ({
  root: {
    height: 192,
    display: "flex",
    maxWidth: 1400,
    margin: "auto",
    background: "#000",
    paddingLeft: 76,
    paddingRight: 76,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 10,
      paddingBottom: 10,
      height: 130,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  imageSrc: {
    backgroundSize: "contain",
    width: 48,
    height: 48,
    display: "block",
    backgroundRepeat: "no-repeat",
  },
  socialIcon: {
    width: 36,
    height: 36,
    display: "block",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  title: {
    color: "#fff",
    fontSize: 15,
    lineHeight: "20px",
    letterSpacing: "-0.02em",
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
    },
  },
  cellMargin: {
    "& > *": {
      marginRight: 10,
      [theme.breakpoints.down('lg')]: {
        marginRight: 5,
      },
    },
  },
  cell: {
    display: "flex",
    alignItems: "center",
    "& > *:last-child": {
      marginRight: 0,
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  grid: {
    margin: 0,
  },
  rightSide: {
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    },
  },
  grantsLogo: {
    display: "block",
    maxWidth: "100%",
    height: "auto",
  },
  link: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      margin: "0 auto !important",
    },
  },
  evetaranCell: {
    display: "block",
    marginTop: 50,
    marginBottom: 50,
  }
});

const Footer = ({ t, classes }) => (
  <div className={classNames(classes.root)}>
    {APP_NAME === "eveteran_public" ? (
      <Grid container={true} className={classes.grid} spacing="8">
        <Grid
          item={true}
          xs={12}
          sm={6}
          className={classNames(classes.cell, classes.cellMargin, classes.evetaranCell)}
        >
          <ListItemText
            secondary={t("CopiringHeadline")}
            classes={{
              primary: classes.title,
              secondary: classes.title,
            }}
          />
          <ListItemText
            secondary={t("Copyring", { year: moment().format("YYYY") })}
            classes={{
              primary: classes.title,
              secondary: classes.title,
            }}
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container={true} className={classes.grid} spacing="8">
        <Grid
          item={true}
          xs={12}
          sm={6}
          className={classNames(classes.cell, classes.cellMargin)}
        >
          {APP_NAME && APP_NAME.toLowerCase() === "diia" ? (
            <>
              <span
                className={classes.imageSrc}
                style={{ backgroundImage: `url(${uaSign})` }}
              />
              <span
                className={classes.imageSrc}
                style={{ backgroundImage: `url(${diiaLogo})` }}
              />
              <Typography variant="h6">
                <ListItemText
                  primary="diia.gov.ua"
                  secondary={t("Copyring", { year: moment().format("YYYY") })}
                  classes={{
                    primary: classes.title,
                    secondary: classes.title,
                  }}
                />
              </Typography>
            </>
          ) : null}
          {APP_NAME && APP_NAME.toLowerCase() === "grants" ? (
            <a
              href="https://grants.vzaemo.diia.gov.ua/"
              className={classes.link}
            >
              <img
                className={classNames(classes.button, classes.grantsLogo)}
                src={grantsLogo}
                alt="logo-footer"
              />
            </a>
          ) : null}
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={6}
          className={classNames(
            classes.cell,
            classes.cellMargin,
            classes.rightSide
          )}
        >
          <Typography variant="body2" className={classes.title}>
            {t("Subscribes")}
          </Typography>
          <a
            href="https://www.facebook.com/diia.gov.ua"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className={classes.socialIcon}
              style={{ backgroundImage: `url(${facebook})` }}
            />
          </a>
          <a
            href="https://t.me/diia_gov"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className={classes.socialIcon}
              style={{ backgroundImage: `url(${telegram})` }}
            />
          </a>
          <a
            href="https://www.instagram.com/diia.gov.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className={classes.socialIcon}
              style={{ backgroundImage: `url(${instagram})` }}
            />
          </a>
          <a
            href="https://vb.me/diia"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className={classes.socialIcon}
              style={{ backgroundImage: `url(${viber})` }}
            />
          </a>
        </Grid>
      </Grid>
    )}
  </div>
);

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const translated = translate("Footer")(Footer);
export default withStyles(styles)(translated);
