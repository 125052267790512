import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import setComponentsId from 'helpers/setComponentsId';
import { translate } from 'react-translate';

import StringElement from 'components/CustomInput/StringElement';

import { Grid, Button, TextField, SnackbarContent, InputAdornment } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Done } from '@mui/icons-material';

import style from 'assets/jss';

import promiseChain from 'helpers/promiseChain';

import {
    verifyActivationCodeEmail,
    sendActivationCodeEmail
} from 'actions/auth';

const regex = /^[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$/;

class EmailInput extends React.Component {
    state = {
        checked: this.props.checked || false,
        showActivation: false,
        code: '',
        value: this.props.value || '',
        error: this.props.error,
        codeError: null
    };

    componentWillReceiveProps({ error }) {
        if (!this.state.error) {
            this.setState({ error });
        }
    }

    valid = email => email.match(regex);

    checkCodeValid = async () => {
        const { t } = this.props;
        const { code } = this.state;
        if (!code) {
            throw t('EMPTY_CODE_ERROR');
        }
    };

    verifyActivationCode = async () => {
        const { t } = this.props;
        const { value, code } = this.state;
        const result = await verifyActivationCodeEmail(value, code);

        if (result !== 'confirm') {
            throw t('ACTIVATION_CODE_INVALID');
        }
    };

    handleFinish = () => {
        const { onChange, onCodeChange } = this.props;
        onChange && onChange({ target: { value: this.state.value } }, () => {
            onCodeChange && onCodeChange({ target: { value: this.state.code } });
        });
    };

    handleToggleActivation = () => promiseChain([
        this.handleSendCode,
        () => this.setState({ showActivation: true })
    ]).catch(error => this.setState({ error }));

    handleChange = ({ target: { value } }) => this.setState({ checked: false, value, error: null });
    handleChangeCode = ({ target: { value } }) => this.setState({ code: value, codeError: null });

    handleSendCode = async () => {
        const { t } = this.props;
        const { value } = this.state;
        const result = await sendActivationCodeEmail(value);
        if (result === 'exist') {
            throw t('EMAIL_ALREADY_EXISTS');
        }
    };

    handleActivate = () => promiseChain([
        this.checkCodeValid,
        this.verifyActivationCode,
        () => this.setState({ checked: true, showActivation: false }, this.handleFinish)
    ]).catch(error => this.setState({ codeError: error }));

    renderActivation() {
        const { t, classes, setId } = this.props;
        const { code, codeError } = this.state;
        return (
            <Fragment>
                <SnackbarContent
                    className={classes.successSnackbar}
                    message={t('ACTIVATIONS_BY_EMAIL_TEXT')}
                />
                <Grid
                    container={true}
                    spacing={8}
                    id={setId('container')}
                >
                    <Grid
                        item={true}
                        xs={6}
                        id={setId('grid')}
                    >
                        <TextField
                            variant="standard"
                            name="code"
                            margin="none"
                            value={code}
                            error={!!codeError}
                            helperText={codeError}
                            label={t('ACTIVATION_CODE')}
                            onChange={this.handleChangeCode}
                            className={classes.fullWidth}
                            id={setId('code')}
                            inputProps={{ maxLength: 6 }} />
                    </Grid>
                    <Grid
                        item={true}
                        xs={6}
                        id={setId('grid-2')}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.fullWidth}
                            onClick={this.handleActivate}
                            id={setId('activate-button')}
                            setId={elementName => setId(`activate-button-${elementName}`)}
                        >
                            {t('ACTIVATE')}
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderActivationRequest() {
        const { t, classes, setId } = this.props;
        return (
            <Button
                variant="contained"
                color="primary"
                className={classes.fullWidth}
                onClick={this.handleToggleActivation}
                id={setId('activate-button')}
                setId={elementName => setId(`activate-button-${elementName}`)}
            >
                {t('ACTIVATE_EMAIL')}
            </Button>
        );
    }

    render() {
        const {
            t,
            classes,
            name,
            label,
            setId
        } = this.props;

        const { value, error, checked, showActivation } = this.state;
        const showActivationRequest = this.valid(value || '') && !checked && !showActivation;

        return (
            <Fragment>
                <StringElement
                    disabled={showActivation || checked}
                    error={error}
                    name={name}
                    label={label}
                    value={value || ''}
                    setId={elementName => setId(`${name}-${elementName}`)}
                    onChange={this.handleChange}
                    sample={t('EMAIL_HELPER')}
                    mask=""
                    InputProps={checked ? {
                        endAdornment: (
                            <InputAdornment>
                                <Done className={classes.successIcon} />
                            </InputAdornment>
                        )
                    } : {}}
                />
                {showActivationRequest && this.renderActivationRequest()}
                {showActivation && this.renderActivation()}
            </Fragment>
        );
    }
}

EmailInput.propTypes = {
    setId: PropTypes.func,
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
    error: PropTypes.object,
    name: PropTypes.string,
    checked: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,

    onCodeChange: PropTypes.func.isRequired
};

EmailInput.defaultProps = {
    setId: setComponentsId('email-input'),
    checked: false,
    error: null,
    value: '',
    name: '',
    label: ''
};

const styled = withStyles(style)(EmailInput);
export default translate('RegisterForm')(styled);
